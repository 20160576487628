<template>
    <section class="careers" v-if="data">
        <!-- <div id="slider"></div> -->

        <div class="work-with-us" v-scroll-reveal="{ distance: '0px', beforeReveal: revealTop }">
            <div class="work-with-us__left-image">

                <!-- <img src="/img/careers/work-with-us__top-left.jpg" alt=""> -->
                <svg class="careersTop" enable-background="new 0 0 569.12 657.35" viewBox="0 0 569.12 657.35" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <clipPath id="a">
                        <path d="m552.83 15.68v208.76c-.01 56.47-22.84 107.44-59.84 144.46-37.02 37-87.99 59.83-144.46 59.84h-208.76v-208.77c.01-56.47 22.84-107.43 59.84-144.46 37.02-37 87.99-59.83 144.46-59.84h208.76z" />
                    </clipPath>
                    <g clip-path="url(#a)">
                        <image class="topSvgLinkedImage" height="800" overflow="visible" transform="matrix(.5147 0 0 .5147 139.7738 15.6683)" width="800" :xlink:href="data.topCopy.svgLinkImage" />
                    </g>
                    <g style="fill:none;stroke:#232221;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10">

                        <!-- red -->
                        <path class="bottom-left" d="m244.68 641.23h-115.52c-29.08 0-55.33-11.76-74.4-30.82-19.05-19.07-30.81-45.32-30.82-74.4v-107.51" stroke="#b22028" />
                        <path class="bottom-right" d="m139.46 428.5c29.08 0 55.33 11.76 74.4 30.82 19.05 19.07 30.81 45.32 30.82 74.4v107.52" stroke="#b22028" />
                        <path class="top-top" d="m343.88 15.67h207.68" stroke="#b22028" />
                        <path class="top-right" d="m551.56 15.67v208.76c-.01 56.47-22.84 107.43-59.84 144.46-37.02 37-87.99 59.83-144.46 59.84h-207.68" stroke="#b22028" />
                        <path class="top-left" d="m24.03 428.73v-208.76c0-112.83 91.47-204.29 204.29-204.29h115.74" stroke="#b22028" />
                        <path class="bottom-top" d="m139.39 428.73h-115.36" stroke="#b22028" />
                        <path class="top-middle" d="m344.07 15.68s-192.37-4.47-204.67 204.29v208.76" stroke="#b22028" />

                        <!-- tan -->
                        <path class="bottom-left" d="m244.68 641.23h-115.52c-29.08 0-55.33-11.76-74.4-30.82-19.05-19.07-30.81-45.32-30.82-74.4v-107.51" stroke="#d6d0b4" />
                        <path class="bottom-right" d="m139.46 428.5c29.08 0 55.33 11.76 74.4 30.82 19.05 19.07 30.81 45.32 30.82 74.4v107.52" stroke="#d6d0b4" />
                        <path class="top-top" d="m343.88 15.67h207.68" stroke="#d6d0b4" />
                        <path class="top-right" d="m551.56 15.67v208.76c-.01 56.47-22.84 107.43-59.84 144.46-37.02 37-87.99 59.83-144.46 59.84h-207.68" stroke="#d6d0b4" />
                        <path class="top-left" d="m24.03 428.73v-208.76c0-112.83 91.47-204.29 204.29-204.29h115.74" stroke="#d6d0b4" />
                        <path class="bottom-top" d="m139.39 428.73h-115.36" stroke="#d6d0b4" />
                        <path class="top-middle" d="m344.07 15.68s-192.37-4.47-204.67 204.29v208.76" stroke="#d6d0b4" />

                        <!-- black -->
                        <path class="bottom-left" d="m244.68 641.23h-115.52c-29.08 0-55.33-11.76-74.4-30.82-19.05-19.07-30.81-45.32-30.82-74.4v-107.51" stroke="#1c1b19" />
                        <path class="bottom-right" d="m139.46 428.5c29.08 0 55.33 11.76 74.4 30.82 19.05 19.07 30.81 45.32 30.82 74.4v107.52" stroke="#1c1b19" />
                        <path class="top-top" d="m343.88 15.67h207.68" stroke="#1c1b19" />
                        <path class="top-right" d="m551.56 15.67v208.76c-.01 56.47-22.84 107.43-59.84 144.46-37.02 37-87.99 59.83-144.46 59.84h-207.68" stroke="#1c1b19" />
                        <path class="top-left" d="m24.03 428.73v-208.76c0-112.83 91.47-204.29 204.29-204.29h115.74" stroke="#1c1b19" />
                        <path class="bottom-top" d="m139.39 428.73h-115.36" stroke="#1c1b19" />
                        <path class="top-middle" d="m344.07 15.68s-192.37-4.47-204.67 204.29v208.76" stroke="#1c1b19" />
                    </g>
                </svg>


                <div class="work-with-us__left-image--openings">
                    <p>{{ totalPositions }}</p>
                </div>
                <p class="work-with-us__left-image__note desktop-only">{{ data.topCopy.note}}</p>
            </div>

            <div class="work-with-us__copy">
                <img src="/img/global/sms-logo--mark.svg" alt="Santa Monica Studies logo mark" class="work-with-us__copy__logo--mark">
                <h1 class="work-with-us__copy__header">work<br>with us</h1>
                <h2 class="work-with-us__copy__subheading">{{ data.topCopy.subheading }}</h2>
                <div class="work-with-us__copy__description" v-html="data.topCopy.description"></div>
                <p class="work-with-us__copy__note mobile-only">{{ data.topCopy.note }}</p>
            </div>

            <div class="watch-trailer">
                <div class="watch-trailer__inner">
                    <router-link :to="'/video/' + data.godOfWar.trailer.assets[0].id">
                        <div class="watch-trailer__inner__bg" :style="{ 'background-image' :  'url('+data.godOfWar.trailer.image+')' }"></div>
                        <div class="watch-trailer__inner__play">
                            <img src="../assets/home/scene1/icon-play.svg" alt = "Video play icon image">
                            <h4>{{ data.godOfWar.trailer.cta }}</h4>
                        </div>
                    </router-link>
                </div>
            </div>


        </div>

        <div class="positions" v-scroll-reveal="{ opacity: 0, distance: 0, beforeReveal: revealGrid }">

            <div class="positions__grid">

                <div class="position design-lt">
                    <svg viewBox="0 0 142.3 140.51" xmlns="http://www.w3.org/2000/svg">
                        <path d="m140.3 138.51h-138.3c0-138.31 138.3-136.51 138.3-136.51z" fill="none" stroke="#232221" stroke-linecap="round" stroke-linejoin="round" stroke-width="4px" />
                    </svg>
                </div>
                <div class="position design-lb">
                    <svg viewBox="0 0 142.3 140.51" xmlns="http://www.w3.org/2000/svg">
                        <path d="m140.3 138.51h-138.3c0-138.31 138.3-136.51 138.3-136.51z" fill="none" stroke="#232221" stroke-linecap="round" stroke-linejoin="round" stroke-width="4px" />
                    </svg>
                </div>
                <div class="position design-r">
                    <svg enable-background="new 0 0 281.9 282.4" viewBox="0 0 281.9 282.4" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="#232221" stroke-linecap="round" stroke-linejoin="round">
                            <path d="m140 140.3v139.7h-140c0-77.2 62.7-139.7 140-139.7z" fill="#e0c565" />
                            <path d="m140 139.7v-139.7h140c0 77.2-62.7 139.7-140 139.7z" fill="none" />
                        </g>
                    </svg>
                </div>
                <div class="position design-bl">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 419.99">
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                                <line style="fill: none;stroke: #232221;stroke-linecap: round;stroke-linejoin: round;" x1="2" y1="2" x2="2" y2="328.32" />
                                <line style="fill: none;stroke: #232221;stroke-linecap: round;stroke-linejoin: round;" x1="2" y1="356.95" x2="2" y2="356.95" />
                                <line style="fill: none;stroke: #232221;stroke-linecap: round;stroke-linejoin: round;stroke-dasharray: 0 30.52;" x1="2" y1="387.47" x2="2" y2="402.73" />
                                <line style="fill: none;stroke: #232221;stroke-linecap: round;stroke-linejoin: round;" x1="2" y1="417.99" x2="2" y2="417.99" />
                            </g>
                        </g>
                    </svg>
                </div>

                <router-link v-for="(positionCategory, i) in data.positions" :key="positionCategory.id" :to="positionCategory.linkTo" append :class="getCategoryClasses(positionCategory)" :event="positionCategory.jobs.length == 0 ? '' : 'click'">
                    <div class="grid-item-content">
                        <img :src="positionCategory.icon" alt="">
                        <p>{{ positionCategory.title }}</p>
                    </div>

                    <div class="details">
                        <img :src="positionCategory.iconHover" alt="">
                        <p>{{ positionCategory.title }}</p>
                        <p class="openings">{{ positionCategory.jobs.length }}</p>
                    </div>
                </router-link>

            </div>


            <div class="watch-trailer">
                <div class="watch-trailer__inner">
                    <router-link :to="'/video/' + data.godOfWar.trailer.assets[0].id">
                        <div class="watch-trailer__inner__bg" :style="{ 'background-image' :  'url('+data.godOfWar.trailer.image+')' }"></div>
                        <div class="watch-trailer__inner__play">
                            <img src="../assets/home/scene1/icon-play.svg" alt = "Play icon for trailer">
                            <h4>{{ data.godOfWar.trailer.cta }}</h4>
                        </div>
                    </router-link>
                </div>
            </div>

        </div>

    </section>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import CareersLandingMixin from '../mixins/CareersLandingTimeline.js'
    import 'jquery-ui/ui/widgets/slider.js';
    import ArrayHelper from '../helpers/ArrayHelper.js'

    import gsap, {Power2,Power3} from 'gsap'
    import SplitText from "gsap/SplitText"
    gsap.registerPlugin(SplitText)

    export default {
        mixins: [CareersLandingMixin],
        props: ['data'],
        data() {
            return {
                totalPositions: 0
            }
        },
        mounted() {
            $(window).scrollTop(0);
            if (this.data) {
                this.countCareers();
                this.initMondrian();
            }
        },
        methods: {
            countCareers() {
                for (var property in this.data.positions) {
                    for (var i = 0; i < this.data.positions[property].jobs.length; i++) {
                        var curJob = this.data.positions[property].jobs[i];
                        this.totalPositions++;
                    }
                }
            },
            initMondrian() {
                Vue.nextTick(() => {
                    this.initTimeline();
                    this.initSlider();
                    // this.revealGrid();
                    setTimeout(() => {
                        // in mixin
                        this.tl.play();
                    }, 600);
                });
            },
            revealTop() {
                var topHeadline = gsap.timeline({delay:1})

                topHeadline.from($('.work-with-us__copy__logo--mark'), .25, { x:-50, opacity: 0, ease: Power2.easeInOut }, 0);

                topHeadline.from($('.work-with-us__copy__subheading'), .5, { x:-50, letterSpacing: ".7em", opacity: 0, ease: Power2.easeInOut }, 0);

                topHeadline.from($('.work-with-us__copy__note'), 1, {opacity:0, x:-50, ease: Power2.easeInOut }, 0);

                topHeadline.from($('.work-with-us__copy__description'), 1.25, {opacity:0, x:-50, ease: Power2.easeInOut }, 0);

                var mySplitText = new SplitText(".work-with-us__copy__header", {
                    type: "lines",
                    linesClass: 'line-split'
                });

                $('.line-split').each(function(i, el) {
                    var curLine = $(el).wrap('<div class="line-split-wrap"></div>');
                })

                var lines = mySplitText.lines; //an array of all the divs that wrap each character
                topHeadline.from(lines, .5, {
                    yPercent: 100,
                    ease: Power3.easeInOut,
                    stagger: 0.03
                }, "-=.75");
            },
            revealGrid() {
                var revealGridTl = gsap.timeline({delay:1})
                revealGridTl.timeScale(3);
                let stagger = 0.3;

                revealGridTl.from($('.grid-item-content'), .9, {
                    y: 50,
                    opacity: 0,
                    force3D:true,
                    stagger:stagger
                    // ease: Power2.easeInOut
                }, .25);
            },
            getCategoryClasses(positionCategory) {
                var numJobs = positionCategory.jobs.length;
                var str = 'position ' + positionCategory.linkTo;
                if(numJobs == 0) str += ' disabled';
                return str;
            },

        },
        watch: {
            data() {
                this.countCareers();
                this.initMondrian();
            },
        }
    }
</script>
