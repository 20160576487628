import gsap, {Power2, Power3} from 'gsap'
import DrawSVGPlugin from "gsap/DrawSVGPlugin"
gsap.registerPlugin(DrawSVGPlugin)

export default {
    data() {
        return {
            slider: null,
            tl: null,
        }
    },
    methods: {
        initSlider() {
            this.$slider = $("#slider");
            
            this.$slider.slider({
                range: false,
                min: 0,
                max: 100,
                step: 0.02,
                value: 0,
                slide: (event, ui) => {
                    this.tl.progress(ui.value / 100).pause();
                },
                stop: () => {
                    this.tl.play();
                }
            });
        },
        updateSlider() {
            this.$slider.slider("value", this.tl.progress() * 100);
        },
        initTimeline() {
            var vw = $(window).width();
            var vh = $(window).height();
            var speed = 0.85;
            this.tl = gsap.timeline({
                onUpdate: this.updateSlider,
                paused: true
            });
            this.tl.timeScale(2.7);

            // Timeline
            this.tl.fromTo($('.work-with-us__left-image').find('.careersTop'), speed,
                { x: -150, opacity: 0, force3D: true },
                { x: 0, opacity: 1, ease: Power2.easeInOut },
                0);
                
            this.tl.add(this.getCareersTopTl(), 0.2);

        },
        getCareersTopTl() {
            var careersTopTl = gsap.timeline({})
            let stagger = 0.3;

            careersTopTl.fromTo($('.top-left'), 3, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
            careersTopTl.fromTo($('.bottom-top'), 1, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
            careersTopTl.fromTo($('.top-right'), 2, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, "-=2.25");
            careersTopTl.fromTo($('.bottom-right'), 1.5, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, "-=4.5");

            careersTopTl.fromTo($('.bottom-left'), 3, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0);
            careersTopTl.fromTo($('.top-middle'), 2, { drawSVG: '100% 100%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 0.75);
            careersTopTl.fromTo($('.top-top'), 1, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', ease: Power3.easeInOut , stagger: stagger}, 2.25);

            careersTopTl.fromTo($('.topSvgLinkedImage'), 2, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=2");

            careersTopTl.fromTo($('.work-with-us__left-image--openings'), 2, { opacity: 0 }, { opacity: 1, ease: Power2.easeInOut }, "-=2");

            return careersTopTl;
        }
    }
}
